import NextImage from 'next/image'
import React from 'react'
import styled, { keyframes } from 'styled-components'

import { ButtonLink, Container, Tag, Text } from 'common/UI'
import { PartnershipsStoryblok } from 'common/types'
import { range } from 'common/utils/array'
import { getAnchorFromCmsLink, getImageAttributes } from 'common/utils/content'

type Props = {
  block: PartnershipsStoryblok
}

export const Partnerships = ({ block, ...rest }: Props): JSX.Element => {
  const { tag, title, description, link, carousel_title } = block

  return (
    <Section {...rest}>
      <Header>
        <div>
          {tag && <Tag label={tag} />}
          <Text as="p" variant="title/large" css={{ marginTop: '1rem' }}>
            {title}
          </Text>
        </div>

        <Text as="p" variant="twenty">
          {description}
        </Text>

        {link?.length && link[0] && (
          <ButtonLink
            rel={getAnchorFromCmsLink(link[0].link).rel}
            href={getAnchorFromCmsLink(link[0].link).href}
            target={getAnchorFromCmsLink(link[0].link).target}
            variant="secondary"
          >
            {link[0].label}
          </ButtonLink>
        )}
      </Header>

      <Carousel>
        {carousel_title && (
          <Text as="p" variant="twenty">
            {carousel_title}
          </Text>
        )}

        <Overflow>
          <PartnersWrapper
            slidePx={`-${(block.logos?.length ?? 0) * (100 + 64)}px`} // 64 -> gap
          >
            {range(0, 3).map((v) => {
              return (
                <React.Fragment key={v}>
                  {block.logos?.map((logo) => {
                    return (
                      <ImageWrapper key={logo.id}>
                        <Logo {...getImageAttributes(logo)} />
                      </ImageWrapper>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </PartnersWrapper>
        </Overflow>
      </Carousel>
    </Section>
  )
}

const Section = styled(Container)`
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  ${({ theme }) => theme.media.md} {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.5rem;

  padding-top: 2.5rem;
  padding-bottom: 2rem;
  width: 100%;

  ${({ theme }) => theme.media.md} {
    padding-top: 5rem;
    padding-bottom: 2.5rem;
    max-width: 583px;

    margin-left: auto;
    margin-right: auto;
  }
`

const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  padding-top: 1.5rem;
`

const Overflow = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  padding-bottom: 5rem;

  &::before {
    content: '';
    height: 100%;
    width: 100px;
    z-index: 20;

    position: absolute;
    left: 0;
    top: 0;

    background: ${({ theme }) =>
      `linear-gradient(to right, ${theme.colors.background.default}, transparent);`};

    ${({ theme }) => theme.media.md} {
      width: 200px;
    }
  }

  &::after {
    content: '';
    width: 100px;
    height: 100%;

    position: absolute;
    right: 0;
    top: 0;

    background: ${({ theme }) =>
      `linear-gradient(to left, ${theme.colors.background.default}, transparent);`};

    ${({ theme }) => theme.media.md} {
      width: 200px;
    }
  }
`

const moveSlide = ({ slidePx }: { slidePx: string }) => keyframes`
  100% {
    transform: translateX(${slidePx});
  }
`

const PartnersWrapper = styled.div<{ slidePx: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40%;
  gap: 4rem;

  animation: ${moveSlide} 60s linear infinite;
`

const ImageWrapper = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  width: fit-content;
  height: 40px;
  flex-shrink: 0;
`

const Logo = styled(NextImage)`
  height: 40px;
  max-width: 300px;
  flex-shrink: 0;
  object-fit: contain;
`
